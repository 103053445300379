

// import React, { useState } from "react";
// import ApiService from './ApiService';

// const apiService = new ApiService();

// const BetaSignupForm = () => {
//     const [formData, setFormData] = useState({
//         email: "",
//         experience: "",
//         reason: "",
//         agreed: false, // Consent checkbox
//     });

//     const [submitted, setSubmitted] = useState(false);
//     const [error, setError] = useState("");
//     const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission in progress

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData({
//             ...formData,
//             [name]: type === "checkbox" ? checked : value,
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Basic validation for email and consent
//         if (!formData.email.includes("@")) {
//             setError("Please enter a valid email address.");
//             return;
//         }
//         if (!formData.agreed) {
//             setError("You must agree to receive the beta invite email.");
//             return;
//         }

//         setError("");
//         setSubmitted(false);
//         setIsSubmitting(true); // Start loading state

//         try {
//             await apiService.sendBetaSignup(formData);
//             setSubmitted(true);
//         } catch (err) {
//             setError(err.message || "An error occurred while submitting the form.");
//         } finally {
//             setIsSubmitting(false); // Stop loading state
//         }
//     };

//     return (
//         <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
//             <h1 className="text-3xl font-bold text-gray-900 mb-4">Sign Up for the iOS Mobile Beta</h1>
//             <p className="text-gray-700 mb-6 text-center max-w-lg">
//                 Submit the form below and you'll receive an e-mail with instructions on how to join the beta momentarily.
//                 <br /><strong>Check your spam folder if you don’t see it.</strong>
//             </p>

//             {submitted ? (
//                 <div className="bg-green-100 text-green-700 p-4 rounded-lg text-center shadow-md max-w-md">
//                     ✅ Thank you for signing up!  You should have the e-mail now.
//                 </div>
//             ) : (
//                 <form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-6 rounded-lg shadow-md space-y-4">
//                     {/* Required Section */}
//                     <div className="mb-6">
//                         <h2 className="text-lg font-semibold text-gray-900 mb-2">Required</h2>
//                         <label className="block text-gray-700 font-semibold mb-1">Email Address</label>
//                         <input
//                             type="email"
//                             name="email"
//                             value={formData.email}
//                             onChange={handleChange}
//                             required
//                             className="w-full p-2 border rounded-lg"
//                             placeholder="Enter your email"
//                         />
//                         <div className="mt-2">
//                             <input
//                                 type="checkbox"
//                                 name="agreed"
//                                 checked={formData.agreed}
//                                 onChange={handleChange}
//                                 className="mr-2"
//                                 required
//                             />
//                             <label className="text-gray-700 font-semibold">
//                                 I agree to receive a single email containing beta access information.
//                             </label>
//                         </div>
//                     </div>

//                     {/* Space Between Sections */}
//                     <div className="h-6"></div>

//                     {/* Optional Section */}
//                     <div>
//                         <h2 className="text-lg font-semibold text-gray-900 mt-4 mb-2">Optional</h2>
//                         <label className="block text-gray-700 font-semibold mb-1">Your Experience Level</label>
//                         <select
//                             name="experience"
//                             value={formData.experience}
//                             onChange={handleChange}
//                             className="w-full p-2 border rounded-lg"
//                         >
//                             <option value="">Select one...</option>
//                             <option value="beginner">Beginner</option>
//                             <option value="intermediate">Intermediate</option>
//                             <option value="advanced">Advanced</option>
//                         </select>

//                         <label className="block text-gray-700 font-semibold mb-1 mt-2">Why do you want to join the beta?</label>
//                         <textarea
//                             name="reason"
//                             value={formData.reason}
//                             onChange={handleChange}
//                             className="w-full p-2 border rounded-lg"
//                             placeholder="Tell us why you're interested!"
//                             rows="3"
//                         />
//                     </div>

//                     {/* Error Message */}
//                     {error && <p className="text-red-600 text-sm">{error}</p>}

//                     {/* Submit Button with Loading State */}
//                     <button
//                         type="submit"
//                         disabled={isSubmitting}
//                         className={`w-full p-3 text-white font-semibold rounded-lg transition ${
//                             isSubmitting ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
//                         }`}
//                     >
//                         {isSubmitting ? 'Receiving Email Shortly...' : 'Apply for Beta'}
//                     </button>
//                 </form>
//             )}
//         </div>
//     );
// };

// export default BetaSignupForm;

import React, { useState } from "react";
import ApiService from './ApiService';

const apiService = new ApiService();

const BetaSignupForm = () => {
    const [formData, setFormData] = useState({
        email: "",
        experience: "",
        reason: "",
        agreed: false, // Consent checkbox
    });

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Submission in progress

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation for email and consent
        if (!formData.email.includes("@")) {
            setError("Please enter a valid email address.");
            return;
        }
        if (!formData.agreed) {
            setError("You must agree to receive the beta invite email.");
            return;
        }

        setError("");
        setSubmitted(false);
        setIsSubmitting(true); // Start loading state

        try {
            await apiService.sendBetaSignup(formData);
            
            // Fire the beta form submission event
            window.gtag && window.gtag('event', 'submit_beta_form', {
                event_category: 'Beta',
                event_label: 'Beta Signup Form'
            });
            
            setSubmitted(true);
        } catch (err) {
            setError(err.message || "An error occurred while submitting the form.");
        } finally {
            setIsSubmitting(false); // Stop loading state
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Sign Up for the iOS Mobile Beta</h1>
            <p className="text-gray-700 mb-6 text-center max-w-lg">
                Submit the form below and you'll receive an e-mail with instructions on how to join the beta momentarily.
                <br /><strong>Check your spam folder if you don’t see it.</strong>
            </p>

            {submitted ? (
                <div className="bg-green-100 text-green-700 p-4 rounded-lg text-center shadow-md max-w-md">
                    ✅ Thank you for signing up! You should have the e-mail now.
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-6 rounded-lg shadow-md space-y-4">
                    {/* Required Section */}
                    <div className="mb-6">
                        <h2 className="text-lg font-semibold text-gray-900 mb-2">Required</h2>
                        <label className="block text-gray-700 font-semibold mb-1">Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded-lg"
                            placeholder="Enter your email"
                        />
                        <div className="mt-2">
                            <input
                                type="checkbox"
                                name="agreed"
                                checked={formData.agreed}
                                onChange={handleChange}
                                className="mr-2"
                                required
                            />
                            <label className="text-gray-700 font-semibold">
                                I agree to receive a single email containing beta access information.
                            </label>
                        </div>
                    </div>

                    {/* Space Between Sections */}
                    <div className="h-6"></div>

                    {/* Optional Section */}
                    <div>
                        <h2 className="text-lg font-semibold text-gray-900 mt-4 mb-2">Optional</h2>
                        <label className="block text-gray-700 font-semibold mb-1">Your Experience Level</label>
                        <select
                            name="experience"
                            value={formData.experience}
                            onChange={handleChange}
                            className="w-full p-2 border rounded-lg"
                        >
                            <option value="">Select one...</option>
                            <option value="beginner">Beginner</option>
                            <option value="intermediate">Intermediate</option>
                            <option value="advanced">Advanced</option>
                        </select>

                        <label className="block text-gray-700 font-semibold mb-1 mt-2">Why do you want to join the beta?</label>
                        <textarea
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            className="w-full p-2 border rounded-lg"
                            placeholder="Tell us why you're interested!"
                            rows="3"
                        />
                    </div>

                    {/* Error Message */}
                    {error && <p className="text-red-600 text-sm">{error}</p>}

                    {/* Submit Button with Loading State */}
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`w-full p-3 text-white font-semibold rounded-lg transition ${
                            isSubmitting ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    >
                        {isSubmitting ? 'Receiving Email Shortly...' : 'Apply for Beta'}
                    </button>
                </form>
            )}
        </div>
    );
};

export default BetaSignupForm;