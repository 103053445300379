

// import React from 'react';
// import WhyFlashFluency from './WhyFlashFluency';
// // import UserFeedback from './UserFeedback';
// import DiscordLink from './DiscordLink';
// import TikTokLink from './TikTokLink';
// import InstagramLink from './InstagramLink';
// // import KakaoLink from './KakaoLink';
// import RoadmapSection from './VisionAndRoadmap';
// import Pricing from './Pricing';

// import bannerImage from './images/ffbanner3.png'


// const HomePage = () => {
//     return (
//         <div className="flex flex-col min-h-screen bg-gray-100">
            
//             {/* TOP NAVIGATION BAR */}
//             <nav className="w-full bg-white shadow-md p-4 flex items-center justify-between fixed top-0 left-0 right-0 z-50 max-w-screen-lg mx-auto">
//                 {/* Clickable Banner */}
//                 <a href="/" className="flex items-center space-x-4">
//                     <img src={bannerImage} alt="Flash Fluency Banner" className="h-12 object-contain" />
//                 </a>

//                 {/* Navigation Links */}
//                 <div className="flex space-x-8 text-gray-700 font-semibold">
//                     <a href="#why" className="hover:text-blue-500">Why Flash Fluency?</a>
//                     <a href="#vision" className="hover:text-blue-500">Vision & Roadmap</a>
//                     <a href="#pricing" className="hover:text-blue-500">Pricing</a>
//                 </div>
//             </nav>


//             {/* MAIN CONTENT */}
//             <main className="flex-grow container mx-auto p-6 max-w-screen-lg pt-20">
                
//                 {/* VIDEO + WELCOME SECTION */}
//                 <div className="flex flex-col md:flex-row items-center justify-between my-10 space-y-8 md:space-y-0 md:space-x-10">
                    
//                     {/* VIDEO PLAYER */}
//                     <div className="w-full md:w-1/2 flex flex-col items-center">
//                         <h3 className="text-xl font-semibold text-red-600 mb-4">Check out our iOS demo!</h3>
//                         <video className="w-64 sm:w-72 md:w-80 lg:w-96 max-w-[400px] rounded-lg shadow-lg" controls>
//                             <source src="/FlashFluencyPromo1.mp4" type="video/mp4" />
//                             Your browser does not support the video tag.
//                         </video>
//                     </div>

//                     {/* JOIN THE APP SECTION */}
//                     <div className="p-8 rounded-2xl bg-blue-50 shadow-xl my-10 border border-blue-200 space-y-6">
//                         <div className="text-center space-y-3">
//                             <h2 className="text-2xl font-bold">Start using Flash Fluency Today.</h2>
//                             <p className="text-lg text-gray-700">
//                                 We're now in Beta! Be one of the first to experience Flash Fluency. Your feedback is invaluable during this time, so let us know what you think—we’d love to hear from you!
//                             </p>
//                         </div>

//                         {/* CTA BUTTONS */}
//                         <div className="space-y-4">
//                             <button 
//                                 onClick={() => window.location.href = '/login'} 
//                                 className="px-8 py-3 bg-green-500 text-white font-bold text-lg rounded-full shadow-lg hover:bg-green-600 hover:scale-105 transition-transform duration-200 w-full"
//                             >
//                                 Try the Web-Based App
//                             </button>

//                             <button 
//                                 onClick={() => window.location.href = '/beta_application'} 
//                                 className="px-8 py-3 bg-green-500 text-white font-bold text-lg rounded-full shadow-lg hover:bg-green-600 hover:scale-105 transition-transform duration-200 w-full"
//                             >
//                                 Get iOS Mobile Access Now
//                             </button>
//                         </div>

//                         <div className="text-center space-y-3">
//                             <h2 className="text-2xl font-bold">Join our Community</h2>
//                             <p className="text-gray-600">
//                                 Connect with us — share your feedback and follow us for updates!
//                             </p>
//                         </div>

//                         <div className="flex justify-center space-x-10 items-center">
//                             <div className="flex flex-col items-center">
//                                 <DiscordLink />
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <InstagramLink />
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <TikTokLink />
//                             </div>
//                             {/* Uncomment if you want to add Kakao link */}
//                             {/* <div className="flex flex-col items-center">
//                                 <KakaoLink />
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>

//                 {/* WHY FLASH FLUENCY SECTION */}
//                 <WhyFlashFluency />

//                 {/* VISION & ROADMAP SECTION */}
//                 <RoadmapSection />

//                 <Pricing />


                

//                 {/* HOW IT WORKS SECTION PLACEHOLDER */}
//                 {/* <section id="how" className="bg-white p-6 rounded-lg shadow-md my-10">
//                     <h2 className="text-2xl font-bold text-gray-900 mb-4">How It Works</h2>
//                     <p className="text-gray-700">(This section will describe the methodology behind Flash Fluency.)</p>
//                 </section> */}

//                 {/* USER FEEDBACK SECTION
//                 <UserFeedback /> */}
//             </main>

//             {/* FOOTER */}
//             <footer className="bg-gray-900 text-white py-6 mt-10">
//                 <nav className="flex justify-center space-x-6 text-sm">
//                     <a href="/support-request" className="hover:underline">Support</a>
//                     <a href="/contact" className="hover:underline">Contact Us</a>
//                     <a href="/terms_of_service" className="hover:underline">Terms of Service</a>
//                     <a href="/privacy_policy" className="hover:underline">Privacy Policy</a>
//                 </nav>
//             </footer>

//         </div>
//     );
// };

// export default HomePage;



import React, { useEffect, useRef } from 'react';
import WhyFlashFluency from './WhyFlashFluency';
// import UserFeedback from './UserFeedback';
import DiscordLink from './DiscordLink';
import TikTokLink from './TikTokLink';
import InstagramLink from './InstagramLink';
// import KakaoLink from './KakaoLink';
import RoadmapSection from './VisionAndRoadmap';
import Pricing from './Pricing';

import bannerImage from './images/ffbanner3.png';

const HomePage = () => {
    const videoRef = useRef();

    useEffect(() => {
        const video = videoRef.current;
        const handlePlay = () => {
            window.gtag && window.gtag('event', 'play_video', {
                event_category: 'Engagement',
                event_label: 'iOS Demo Video'
            });
        };
        video?.addEventListener('play', handlePlay);
        return () => video?.removeEventListener('play', handlePlay);
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <nav className="w-full bg-white shadow-md p-4 flex items-center justify-between fixed top-0 left-0 right-0 z-50 max-w-screen-lg mx-auto">
                <a href="/" className="flex items-center space-x-4">
                    <img src={bannerImage} alt="Flash Fluency Banner" className="h-12 object-contain" />
                </a>
                <div className="flex space-x-8 text-gray-700 font-semibold">
                    <a href="#why" className="hover:text-blue-500">Why Flash Fluency?</a>
                    <a href="#vision" className="hover:text-blue-500">Vision & Roadmap</a>
                    <a href="#pricing" className="hover:text-blue-500">Pricing</a>
                </div>
            </nav>

            <main className="flex-grow container mx-auto p-6 max-w-screen-lg pt-20">
                <div className="flex flex-col md:flex-row items-center justify-between my-10 space-y-8 md:space-y-0 md:space-x-10">
                    <div className="w-full md:w-1/2 flex flex-col items-center">
                        <h3 className="text-xl font-semibold text-red-600 mb-4">Check out our iOS demo!</h3>
                        <video ref={videoRef} className="w-64 sm:w-72 md:w-80 lg:w-96 max-w-[400px] rounded-lg shadow-lg" controls>
                            <source src="/FlashFluencyPromo1.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className="p-8 rounded-2xl bg-blue-50 shadow-xl my-10 border border-blue-200 space-y-6">
                        <div className="text-center space-y-3">
                            <h2 className="text-2xl font-bold">Start using Flash Fluency Today.</h2>
                            <p className="text-lg text-gray-700">
                                We're now in Beta! Be one of the first to experience Flash Fluency. Your feedback is invaluable during this time, so let us know what you think—we’d love to hear from you!
                            </p>
                        </div>

                        <div className="space-y-4">
                            <button 
                                onClick={() => {
                                    window.gtag && window.gtag('event', 'click', {
                                        event_category: 'CTA',
                                        event_label: 'Try Web App'
                                    });
                                    window.location.href = '/login';
                                }} 
                                className="px-8 py-3 bg-green-500 text-white font-bold text-lg rounded-full shadow-lg hover:bg-green-600 hover:scale-105 transition-transform duration-200 w-full"
                            >
                                Try the Web-Based App
                            </button>

                            <button 
                                onClick={() => {
                                    window.gtag && window.gtag('event', 'click', {
                                        event_category: 'CTA',
                                        event_label: 'Apply for iOS Beta'
                                    });
                                    window.location.href = '/beta_application';
                                }} 
                                className="px-8 py-3 bg-green-500 text-white font-bold text-lg rounded-full shadow-lg hover:bg-green-600 hover:scale-105 transition-transform duration-200 w-full"
                            >
                                Get iOS Mobile Access Now
                            </button>
                        </div>

                        <div className="text-center space-y-3">
                            <h2 className="text-2xl font-bold">Join our Community</h2>
                            <p className="text-gray-600">
                                Connect with us — share your feedback and follow us for updates!
                            </p>
                        </div>

                        <div className="flex justify-center space-x-10 items-center">
                            <div className="flex flex-col items-center">
                                <DiscordLink />
                            </div>
                            <div className="flex flex-col items-center">
                                <InstagramLink />
                            </div>
                            <div className="flex flex-col items-center">
                                <TikTokLink />
                            </div>
                        </div>
                    </div>
                </div>

                <WhyFlashFluency />
                <RoadmapSection />
                <Pricing />
            </main>

            <footer className="bg-gray-900 text-white py-6 mt-10">
                <nav className="flex justify-center space-x-6 text-sm">
                    <a href="/support-request" className="hover:underline">Support</a>
                    <a href="/contact" className="hover:underline">Contact Us</a>
                    <a href="/terms_of_service" className="hover:underline">Terms of Service</a>
                    <a href="/privacy_policy" className="hover:underline">Privacy Policy</a>
                </nav>
            </footer>
        </div>
    );
};

export default HomePage;