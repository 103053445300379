
import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Terms of Service v1.2</h1>
            <p className="text-center mb-8">Last Updated: 02/28/2025</p>

            <section className="bg-white p-8 rounded-lg shadow-lg border-2 border-gray-300">
                <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
                <p className="mb-4">
                    By accessing and using FlashFluency (the "App" or "Service"), you accept and agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use this App.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
                <p className="mb-4">
                    FlashFluency provides users with access to a collection of educational resources, including:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Sample sentences</li>
                    <li>Vocabulary data</li>
                    <li>Various language-learning features</li>
                </ul>
                <p className="mb-4">
                    Any new features or tools added to the current Service will also be subject to these Terms of Service.
                </p>

                <h2 className="text-2xl font-semibold mb-4">3. User Responsibilities</h2>
                <p className="mb-4">
                    By using FlashFluency, you agree to:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Use the service only for educational purposes.</li>
                    <li>Not engage in any activity that could harm, disrupt, or impair the functionality of the service.</li>
                    <li>Provide accurate and truthful information when registering or interacting with the service.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">4. Account Registration</h2>
                <p className="mb-4">
                    To access certain features, you may be required to create an account. You are responsible for maintaining the security of your account credentials and must not share them with others.
                </p>

                <h2 className="text-2xl font-semibold mb-4">5. Account Deletion</h2>
                <p className="mb-4">
                    Users have the right to delete their account at any time through the <strong>Settings</strong> section within the app. Once an account is deleted, all associated data—including stored progress and preferences—will be permanently erased and cannot be recovered.
                </p>
                <p className="mb-4">
                    If you encounter any issues with account deletion, please contact us at <a href="mailto:flashfluencydev@gmail.com" className="text-blue-700">flashfluencydev@gmail.com</a>.
                </p>

                <h2 className="text-2xl font-semibold mb-4">6. Age Restrictions</h2>
                <p className="mb-4">
                    FlashFluency is intended for users aged <strong>13 and older</strong>. We do not knowingly collect personal data from children under 13. If a parent or guardian believes that their child has provided personal information, they may request its deletion by contacting us at <a href="mailto:flashfluencydev@gmail.com" className="text-blue-700">flashfluencydev@gmail.com</a>.
                </p>
                <p className="mb-4">
                    For users in the European Union, FlashFluency requires users to be at least <strong>16 years old</strong> to provide personal data unless parental consent is obtained.
                </p>

                <h2 className="text-2xl font-semibold mb-4">7. Intellectual Property</h2>
                <p className="mb-4">
                    All content provided by FlashFluency—including text, graphics, and software—is protected by intellectual property laws. Unauthorized reproduction, distribution, or modification is prohibited.
                </p>

                <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
                <p className="mb-4">
                    FlashFluency is provided "as is" without any warranties, express or implied. We do not guarantee that the Service will always function without interruptions or errors.
                </p>

                <h2 className="text-2xl font-semibold mb-4">9. Changes to Terms</h2>
                <p className="mb-4">
                    We reserve the right to update or modify these Terms of Service at any time. Any changes will be posted on this page.
                </p>
                <p className="mb-4">
                    If we make significant changes that affect user rights, we will notify users through an in-app message or email before the changes take effect. Continued use of the Service after any changes constitutes your acceptance of the updated terms.
                </p>
            </section>
        </div>
    );
};

export default TermsOfService;