import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SupportRequest = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        category: 'bug-report',
        description: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // new state for submission in progress

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.email.includes('@')) {
            setError('Please enter a valid email address.');
            return;
        }

        setError('');
        setSubmitted(false);
        setIsSubmitting(true); // show "Sending..." and disable the button

        try {
            // Construct a FormData object
            const data = new FormData();
            data.append('email', formData.email);
            data.append('category', formData.category);
            data.append('description', formData.description);

            // Optional hidden fields
            data.append('_subject', 'Support Request Submission');
            data.append('_captcha', 'false');
            // data.append('_next', 'https://yoursite.com/thank-you');

            const response = await fetch('https://formsubmit.co/flashfluencydev@gmail.com', {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                throw new Error(`Form submission error: ${response.statusText}`);
            }

            console.log('Support Request Sent:', formData);
            setSubmitted(true);
        } catch (err) {
            setError('Error sending form. Please try again.');
            console.error('Error sending form:', err);
        } finally {
            // always end loading state
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Submit a Support Request</h1>
            <p className="text-gray-700 mb-6 text-center max-w-lg">
                Need help? Fill out the form below, and our support team will assist you as soon as possible.
            </p>

            {/* Contact Information Section */}
            <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md text-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900">Contact Us</h2>
                <p className="text-gray-700">You can also reach us directly at:</p>
                <p className="text-gray-900 font-medium">Email: flashfluencydev@gmail.com</p>
            </div>

            {submitted ? (
                <div className="bg-green-100 text-green-700 p-4 rounded-lg text-center shadow-md max-w-md">
                    ✅ Your request has been submitted! We’ll get back to you shortly.
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="w-full max-w-md bg-white p-6 rounded-lg shadow-md space-y-4"
                >
                    <label className="block text-gray-700 font-semibold mb-1">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border rounded-lg"
                        placeholder="Enter your email"
                    />

                    <label className="block text-gray-700 font-semibold mb-1">Issue Category</label>
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                    >
                        <option value="bug-report">Bug Report</option>
                        <option value="report-abuse">Report Abuse</option>
                        <option value="cannot-access-account">I cannot access my account</option>
                        <option value="purchase-issue">Purchase or Subscription Issue</option>
                        <option value="account-deletion">Account Deletion Request</option>
                    </select>

                    <label className="block text-gray-700 font-semibold mb-1">Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border rounded-lg"
                        placeholder="Describe your issue..."
                        rows="3"
                    />

                    {error && <p className="text-red-600 text-sm">{error}</p>}

                    <button
                        type="submit"
                        disabled={isSubmitting} // disable button during submission
                        className={`w-full p-3 text-white font-semibold rounded-lg transition ${
                            isSubmitting ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    >
                        {isSubmitting ? 'Sending...' : 'Submit Request'}
                    </button>
                </form>
            )}

            <button
                onClick={() => navigate('/')}
                className="mt-6 px-6 py-3 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition"
            >
                Go Back to Home
            </button>
        </div>
    );
};

export default SupportRequest;