// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// // import readingPracticeImage from './images/reading_practice.png';
// import readingPracticeImage from './images/ReadingSquirrelNight.png';
// // import listeningPracticeImage from './images/listening_practice.png';
// // import listeningPracticeImage from './images/Listening_Practice_Squirrel2.png';
// import listeningPracticeImage from './images/ListeningTurtleNight.png'
// import grammarImage from './images/Grammar.png'
// import vocabularyImage from './images/Vocabulary.png'
// import writingImage from './images/WritingHedgehogNight.png'
// import statsImage from './images/stats.png'
// import userSettingsImage from './images/user_settings_image.png'; // Add your profile/settings image here
// import Notifications from './Notifications'; // Import the Notifications component

// function Platform() {
//   let navigate = useNavigate();

//   return (
//     <div className="home-screen">
//       <div className="top-bar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', background: '#f0f0f0', borderBottom: '2px solid black' }}>
//         <div className="notifications" style={{ display: 'flex', alignItems: 'center' }}>
//           <Notifications /> {/* Use the Notifications component */}
//         </div>
//         <img
//           src={userSettingsImage}
//           alt="userSettings"
//           onClick={() => navigate('/settings')}
//           style={{ border: '3px solid black', width: '50px', height: 'auto', cursor: 'pointer' }}
//         />
//       </div>
//       <div className="image-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
//         <img
//           src={readingPracticeImage}
//           alt="Reading Practice"
//           onClick={() => navigate('/reading_practice')}
//           style={{ border: '3px solid black', width: '400px', height: 'auto', cursor: 'pointer', margin: '20px' }}
//         />
//         <img
//           src={listeningPracticeImage}
//           alt="Listening Practice"
//           onClick={() => navigate('/listening_practice')}
//           style={{ border: '3px solid black', width: '400px', height: 'auto', cursor: 'pointer', margin: '20px' }}
//         />
//         <img
//           src={writingImage}
//           alt="Cloze Practice"
//           onClick={() => navigate('/cloze')}
//           style={{ border: '3px solid black', width: '400px', height: 'auto', cursor: 'pointer', margin: '20px' }}
//         />
//         <img
//           src={grammarImage}
//           alt="Grammar"
//           onClick={() => navigate('/grammar_lessons')}
//           style={{ border: '3px solid black', width: '400px', height: 'auto', cursor: 'pointer', margin: '20px' }}
//         />
//         <img
//           src={vocabularyImage}
//           alt="Vocabulary"
//           onClick={() => navigate('/vocabulary')}
//           style={{ border: '3px solid black', width: '400px', height: 'auto', cursor: 'pointer', margin: '20px' }}
//         />
//         <img
//           src={statsImage}
//           alt="Statistics"
//           onClick={() => navigate('/statistics')}
//           style={{ border: '3px solid black', width: '400px', height: 'auto', cursor: 'pointer', margin: '20px' }}
//         />
//       </div>
//     </div>
//   );
// }

// export default Platform;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import readingPracticeImage from './images/ReadingSquirrelNight.png';
import listeningPracticeImage from './images/ListeningTurtleNight.png';
import grammarImage from './images/Grammar.png';
import vocabularyImage from './images/Vocabulary.png';
import writingImage from './images/WritingHedgehogNight.png';
import statsImage from './images/stats.png';
import userSettingsImage from './images/user_settings_image.png';
import Notifications from './Notifications';

function Platform() {
  let navigate = useNavigate();

  // A reusable ImageCard component for overlaying text on an image
  const ImageCard = ({ src, alt, overlayText, onClick }) => (
    <div
      className="image-wrapper"
      onClick={onClick}
      style={{ position: 'relative', margin: '20px', cursor: 'pointer' }}
    >
      <img
        src={src}
        alt={alt}
        style={{ border: '3px solid black', width: '400px', height: 'auto' }}
      />
      <div
        className="overlay"
        style={{
          position: 'absolute',
          bottom: '0',
          width: '100%',
          backgroundColor: 'rgba(240,240,240,0.7)',
          textAlign: 'center',
          padding: '5px',
          fontWeight: 'bold',
          borderBottom: '3px solid black'
        }}
      >
        {overlayText}
      </div>
    </div>
  );

  return (
    <div className="home-screen">
      <div
        className="top-bar"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          background: '#f0f0f0',
          borderBottom: '2px solid black'
        }}
      >
        <div className="notifications" style={{ display: 'flex', alignItems: 'center' }}>
          <Notifications />
        </div>
        <img
          src={userSettingsImage}
          alt="User Settings"
          onClick={() => navigate('/settings')}
          style={{ border: '3px solid black', width: '50px', height: 'auto', cursor: 'pointer' }}
        />
      </div>

      <div
        className="image-container"
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        <ImageCard
          src={readingPracticeImage}
          alt="Reading Practice"
          overlayText="Reading"
          onClick={() => navigate('/reading_practice')}
        />
        <ImageCard
          src={listeningPracticeImage}
          alt="Listening Practice"
          overlayText="Listening"
          onClick={() => navigate('/listening_practice')}
        />
        <ImageCard
          src={writingImage}
          alt="Cloze Practice"
          overlayText="Fill-in-the-Blank"
          onClick={() => navigate('/cloze')}
        />
        <ImageCard
          src={grammarImage}
          alt="Grammar"
          overlayText="Grammar"
          onClick={() => navigate('/grammar_lessons')}
        />
        <ImageCard
          src={vocabularyImage}
          alt="Vocabulary"
          overlayText="Vocabulary"
          onClick={() => navigate('/vocabulary')}
        />
        <ImageCard
          src={statsImage}
          alt="Statistics"
          overlayText="Statistics"
          onClick={() => navigate('/statistics')}
        />
      </div>
    </div>
  );
}

export default Platform;