

// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDiscord } from '@fortawesome/free-brands-svg-icons'; // Import the Discord icon

// const DiscordLink = () => {
//     return (
//         <div className="text-center mt-8">
//             <a href="https://discord.gg/rtqrugEEQh" target="_blank" rel="noopener noreferrer">
//                 <FontAwesomeIcon icon={faDiscord} size="3x" className="text-blue-500" />
//             </a>
//             <p className="mt-2 text-sm text-gray-600">Discord</p>
//         </div>
//     );
// };

// export default DiscordLink;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

const DiscordLink = () => {
    return (
        <div className="text-center mt-8">
            <a 
                href="https://discord.gg/rtqrugEEQh" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() =>
                    window.gtag && window.gtag('event', 'click', {
                        event_category: 'Social',
                        event_label: 'Discord'
                    })
                }
            >
                <FontAwesomeIcon icon={faDiscord} size="3x" className="text-blue-500" />
            </a>
            <p className="mt-2 text-sm text-gray-600">Discord</p>
        </div>
    );
};

export default DiscordLink;