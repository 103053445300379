import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        message: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.email.includes('@')) {
            setError('Please enter a valid email address.');
            return;
        }

        setError('');
        setSubmitted(false);
        setIsSubmitting(true); // indicate loading

        try {
            // Build a FormData object
            const data = new FormData();
            data.append('email', formData.email);
            data.append('message', formData.message);
            data.append('_subject', 'Contact Us Submission');
            data.append('_captcha', 'false');

            const response = await fetch('https://formsubmit.co/flashfluencydev@gmail.com', {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                throw new Error(`Form submission error: ${response.statusText}`);
            }

            console.log("Contact Form Sent:", formData);
            setSubmitted(true);
        } catch (err) {
            setError("Error sending form. Please try again.");
            console.error("Error sending form:", err);
        } finally {
            // always end loading state
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Contact Us</h1>
            <p className="text-gray-700 mb-6 text-center max-w-lg">
                Have a question or a business inquiry? Send us a message, and we’ll get back to you as soon as possible.
            </p>

            {submitted ? (
                <div className="bg-green-100 text-green-700 p-4 rounded-lg text-center shadow-md max-w-md">
                    ✅ Your message has been sent! We'll be in touch soon.
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-6 rounded-lg shadow-md space-y-4">
                    <label className="block text-gray-700 font-semibold mb-1">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border rounded-lg"
                        placeholder="Enter your email"
                    />

                    <label className="block text-gray-700 font-semibold mb-1">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border rounded-lg"
                        placeholder="Your message..."
                        rows="3"
                    />

                    {error && <p className="text-red-600 text-sm">{error}</p>}

                    <button
                        type="submit"
                        disabled={isSubmitting} // disable while sending
                        className={`w-full p-3 text-white font-semibold rounded-lg transition ${
                            isSubmitting ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    >
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                </form>
            )}

            <button
                onClick={() => navigate('/')}
                className="mt-6 px-6 py-3 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition"
            >
                Go Back to Home
            </button>
        </div>
    );
};

export default ContactUs;