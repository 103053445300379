

import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy v1.2</h1>
            <p className="text-center mb-8">Last Updated: 02/28/2025</p>

            <section className="bg-white p-8 rounded-lg shadow-lg border-2 border-gray-300">
                <h2 className="text-2xl font-semibold mb-4">1. User Data Collection</h2>
                <p className="mb-4">
                    We collect and store limited personal data to enhance your experience on our platform. The data we store includes:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Email Address</strong>: Used for account creation, authentication, and communication.</li>
                    <li><strong>User Proficiency Metrics</strong>: We track user-specific proficiencies, such as vocabulary and grammar skills, to personalize your learning experience and provide tailored content.</li>
                    <li><strong>User Queries</strong>: The questions you ask via the <strong>'Ask Flash'</strong> feature are stored to help us improve our sentence examples and exercises.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">2. Cookies and Tracking Technologies</h2>
                <p className="mb-4">
                    We do not use cookies or tracking technologies that store your personal information. Any temporary session data is automatically discarded once your session ends.
                </p>

                <h2 className="text-2xl font-semibold mb-4">3. Third-Party Services</h2>
                <p className="mb-4">
                    FlashFluency utilizes third-party services to enhance certain functionalities of our platform. Specifically:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Ask Flash Feature & OpenAI</strong>: The questions you submit through <strong>"Ask Flash"</strong> are sent to <strong>OpenAI’s GPT API</strong> to generate responses.
                    </li>
                </ul>
                <p className="mb-4">
                    OpenAI may process this data in accordance with their own <a href="https://openai.com/privacy" className="text-blue-700" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. FlashFluency does <strong>not</strong> control how OpenAI stores or uses this data.
                </p>
                <p className="mb-4">
                    <strong>Important Notice:</strong> AI-generated responses may not always be accurate, and users should verify information independently before relying on AI-generated content.
                </p>

                <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
                <p className="mb-4">
                    We implement industry-standard security measures to protect your personal information against unauthorized access, including:
                </p>
                <ul className="list-disc pl-6 mb-4">
                    <li><strong>AES-256 encryption</strong> for data at rest, ensuring stored user data is securely encrypted.</li>
                    <li><strong>TLS 1.2+ encryption</strong> for data in transit, safeguarding communications between users and our servers.</li>
                    <li><strong>Secure authentication protocols</strong> to protect user accounts.</li>
                    <li><strong>Regular security audits</strong> to maintain compliance with industry best practices.</li>
                </ul>
                <p className="mb-4">
                    These measures help ensure that user data remains private and protected against unauthorized access.
                </p>

                <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
                <p className="mb-4">
                    We retain your data only as long as necessary to fulfill the purposes outlined in this policy. When you delete your account, your personal data will be permanently removed within 30 days, except for residual backup copies that are purged within 90 days.
                </p>

                <h2 className="text-2xl font-semibold mb-4">6. Your Rights Regarding Your Data</h2>
                <p className="mb-4">
                    Depending on applicable data laws (such as GDPR or CCPA), you may have the right to:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Access the personal data we hold about you.</li>
                    <li>Request corrections to inaccurate or incomplete data.</li>
                    <li>Request deletion of your personal data (refer to Section 7: Account Deletion).</li>
                    <li>Object to the processing of your data under certain circumstances.</li>
                </ul>
                <p className="mb-4">
                    To exercise these rights, please contact us at <a href="mailto:flashfluencydev@gmail.com" className="text-blue-700">flashfluencydev@gmail.com</a>.
                </p>

                <h2 className="text-2xl font-semibold mb-4">7. Account Deletion</h2>
                <p className="mb-4">
                    Users can delete their account and all associated data at any time via the <strong>Settings</strong> section within the app. Once an account is deleted, all personal data will be permanently erased and cannot be recovered.
                </p>
                <p className="mb-4">
                    If you encounter any issues with account deletion, please contact us at <a href="mailto:flashfluencydev@gmail.com" className="text-blue-700">flashfluencydev@gmail.com</a>.
                </p>

                <h2 className="text-2xl font-semibold mb-4">8. Age Restrictions</h2>
                <p className="mb-4">
                    FlashFluency is intended for users aged <strong>13 and older</strong>. We do not knowingly collect personal data from children under 13. If a parent or guardian believes that their child has provided personal information, they may request its deletion by contacting us.
                </p>
                <p className="mb-4">
                    For users in the European Union, FlashFluency requires users to be at least <strong>16 years old</strong> to provide personal data unless verifiable parental consent is obtained. Parents may contact us at <a href="mailto:flashfluencydev@gmail.com" className="text-blue-700">flashfluencydev@gmail.com</a> for assistance.
                </p>

                <h2 className="text-2xl font-semibold mb-4">9. Changes to This Privacy Policy</h2>
                <p className="mb-4">
                    We may update our Privacy Policy periodically. Any changes will be posted on this page, and you are encouraged to review this policy regularly for updates.
                </p>

                <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
                <p className="mb-4">
                    If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <p className="text-blue-700 underline">flashfluencydev@gmail.com</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;