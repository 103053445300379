// src/components/WhyFlashFluency.js
import React from 'react';


const WhyFlashFluency = () => {
    const features = [
        {
            title: "+1 Incremental Learning",
            description: "Every sentence introduces exactly one new vocabulary word or grammar point, ensuring stress-free, effective, and comprehensible input. New concepts seamlessly build upon previously learned material."
        },
        {
            title: "Contextual and Realistic Learning",
            description: "Authentic, realistic sentences provide nuanced examples of vocabulary and grammar usage, helping you learn language naturally and effectively."
        },
        {
            title: "Extensive and Expanding Content",
            description: "Explore over 2,000 vocabulary terms and 300 grammar mini-lessons. Instantly access detailed breakdowns and explanations. Expanding to 6,000 words by Q2 2025."
        },
        {
            title: "Adaptive and Personalized Learning",
            description: "Advanced algorithm with adapted SRS (spaced repetition) balances new content and review, optimizing your learning based on precise proficiency tracking."
        },
        {
            title: "Precision Proficiency Tracking",
            description: "Detailed insights into your progress across vocabulary, grammar, reading, and listening tasks."
        },
        {
            title: "Comprehensive Skill Development",
            description: "Reading, listening, and fill-in-the-blank completed. Currently working on pronunciation. Plans for translation, writing, and conversational exercises."
        },
        {
            title: "Instant Support and Feedback",
            description: "One-click access to vocabulary and grammar explanations and instant AI-driven assistance through Ask Flash."
        }
    ];

    return (
        <section className="p-8 rounded-2xl bg-blue-50 shadow-xl my-10 border border-blue-200">
            <h2 className="text-3xl font-extrabold text-gray-700 text-center mb-8">
                Why Flash Fluency?
            </h2>
            <ul className="divide-y divide-gray-300">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-start py-4">
                        <div>
                            <h3 className="text-xl font-semibold">{feature.title}</h3>
                            <p className="text-gray-700 mt-1">{feature.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default WhyFlashFluency;