import React from 'react';


const RoadmapSection = () => {
    return (
      <section id="vision" className="p-8 rounded-2xl bg-blue-50 shadow-xl my-10 border border-blue-200">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Vision & Roadmap</h2>
  
        {/* Vision & Current Status */}
        <div className="mb-8 space-y-6">
          <div>
            <h3 className="text-xl font-semibold text-blue-600 mb-2">Step 1 - Anki on Steroids</h3>
            <p className="ml-6 text-gray-700">
                Imagine Anki, but effortless. Instead of spending your time creating cards and sentences, you get the perfect learning content automatically delivered to you every single time. Receive clear, instant feedback on anything you're struggling with, and master exactly the vocabulary you'll use most in real-life conversations.            </p>
          </div>
  
          <div>
            <h3 className="text-xl font-semibold text-blue-600 mb-2">Step 2 - Comprehensive Korean Learning System</h3>
            <p className="ml-6 text-gray-700">
              We aim to offer a fully comprehensive, structured yet personalized system for continual learning and assessment of Korean.
            </p>
          </div>
        </div>
  
        {/* Feature Development Timeline */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Feature Development Timeline</h3>
          <table className="w-full text-left border-collapse shadow-sm">
            <thead className="bg-blue-100">
              <tr>
                <th className="border-b px-4 py-3">Feature</th>
                <th className="border-b px-4 py-3">Status</th>
                <th className="border-b px-4 py-3">Release</th>
              </tr>
            </thead>
            <tbody>
              {[  
                ["Vocabulary Learning (Anki-style SRS)", "✅ Completed", "Available Now"],
                ["Reading, Listening, Fill-in-the-Blank", "✅ Completed", "Available Now"],
                ["+1 Comprehensible Input", "✅ Completed", "Available Now"],
                ["1-Click Grammar/Vocab & Ask Flash", "✅ Completed", "Available Now"],
                ["Precise Proficiency Tracking", "✅ Completed", "Available Now"],
                ["Personalized Vocab & Topics", "🚧 In Development", "Q2 2025"],
                ["Pronunciation Assessment", "🧪 Research Stage", "2025"],
                ["Writing, Translation, Conversation", "🚧 Planned Development", "2026"]
              ].map(([feature, status, release], idx) => (
                <tr key={idx} className="even:bg-blue-50">
                  <td className="border-b px-4 py-3">{feature}</td>
                  <td className="border-b px-4 py-3">{status}</td>
                  <td className="border-b px-4 py-3">{release}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* What's Coming Next */}
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">What's Coming Next</h3>
  
          <p className="text-gray-700">
            In <strong>Q2 2025</strong>, expect personalized vocabulary and topic-driven learning. Select from thousands of frequent words or specific topics, or let our app guide you automatically through new vocabulary and grammar.
          </p>
  
          <p className="text-gray-700">
            By <strong>2026</strong>, Flash Fluency will evolve into an all-encompassing language platform with robust pronunciation assessment, interactive writing, translation challenges, and conversational exercises.
          </p>
  
          <p className="text-gray-700">
            Start conversations on your favorite topics, receive real-time writing guidance, and automatically reinforce new vocabulary.
          </p>
  
          <p className="text-gray-700">
            Finally, our <strong>capstone conversational exercise</strong> will dynamically test your fluency and pronunciation, ensuring you speak Korean naturally and confidently.
          </p>
  
          <div className="mt-6">
            <p className="text-gray-800 font-semibold">
              🎯 <strong>Short-Term Goal:</strong> Perfecting your vocabulary and grammar.
            </p>
            <p className="text-gray-800 font-semibold">
              🚀 <strong>Future Goal:</strong> Revolutionizing comprehensive language fluency.
            </p>
          </div>
        </div>
      </section>
    );
  };
  
  export default RoadmapSection;
  