// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTiktok } from '@fortawesome/free-brands-svg-icons';

// const TikTokLink = () => {
//   return (
//     <div className="text-center mt-8">
//       <a 
//         href="https://www.tiktok.com/@flashfluencykorean" 
//         target="_blank" 
//         rel="noopener noreferrer"
//       >
//         <FontAwesomeIcon icon={faTiktok} size="3x" className="text-black" />
//       </a>
//       <p className="mt-2 text-sm text-gray-600">TikTok</p>
//     </div>
//   );
// };

// export default TikTokLink;


import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';

const TikTokLink = () => {
    return (
        <div className="text-center mt-8">
            <a 
                href="https://www.tiktok.com/@flashfluencykorean" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() =>
                    window.gtag && window.gtag('event', 'click', {
                        event_category: 'Social',
                        event_label: 'TikTok'
                    })
                }
            >
                <FontAwesomeIcon icon={faTiktok} size="3x" className="text-black" />
            </a>
            <p className="mt-2 text-sm text-gray-600">TikTok</p>
        </div>
    );
};

export default TikTokLink;
