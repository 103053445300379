// const Pricing = () => {
//     const plans = [
//         {
//             name: "Free",
//             price: "$0 / month",
//             features: [
//                 "1 core exercise per day (Reading, Listening, Fill-in-the-blank)",
//             ],
//             available: "Available Now"
//         },
//         {
//             name: "Starter",
//             price: "$8 / month",
//             features: [
//                 "Unlimited core exercises (Reading, Listening, Fill-in-the-blank)",
//                 "Personalized vocabulary selection",
//             ],
//             available: "Launching Q3 2025"
//         },
//         {
//             name: "Premium",
//             price: "$15 / month",
//             features: [
//                 "Everything in Starter Tier",
//                 "Limited AI exercises (Translation, Writing, Conversation, Pronunciation)"
//             ],
//             available: "Launching 2026"
//         },
//         {
//             name: "Unlimited",
//             price: "$20 / month",
//             features: [
//                 "Everything in Premium Tier",
//                 "Unlimited Access to AI exercises"
//             ],
//             available: "Launching 2026"
//         }
//     ];

//     return (
//         <section id="pricing" className="p-8 rounded-2xl bg-blue-50 shadow-xl my-10 border border-blue-200">
//             <h2 className="text-3xl font-extrabold text-gray-700 text-center mb-4">
//                 Potential Pricing Plans
//             </h2>
//             <p className="text-center text-gray-600 mb-8">
//                 Currently, during our beta period, all (completed) features are fully accessible and unlimited.  No pricing plans are yet in place. To maintain transparency, here's a clear look at our future pricing plans.  Pricing is subject to change.
//             </p>
//             <div className="grid md:grid-cols-4 gap-4">
//                 {plans.map((plan, index) => (
//                     <div key={index} className="p-6 bg-white rounded-xl shadow-lg border border-gray-300">
//                         <h3 className="text-2xl font-bold text-gray-800">{plan.name}</h3>
//                         <p className="text-xl text-gray-600 font-semibold mt-2">{plan.price}</p>
//                         <p className="text-sm text-gray-500 italic mb-3">{plan.available}</p>
//                         <ul className="mt-4 space-y-2">
//                             {plan.features.map((feature, idx) => (
//                                 <li key={idx} className="text-gray-700">✓ {feature}</li>
//                             ))}
//                         </ul>
//                     </div>
//                 ))}
//             </div>
//         </section>
//     );
// };

// export default Pricing;
const Pricing = () => {
    const plans = [
        {
            name: "Free",
            price: "$0 / month",
            features: [
                "1 core exercise per day (Reading, Listening, Fill-in-the-blank)",
            ],
            available: ""
        },
        {
            name: "Starter",
            price: "$8 / month",
            features: [
                "Unlimited core exercises (Reading, Listening, Fill-in-the-blank)",
                "Personalized vocabulary selection",
            ],
            trial: "1 week free trial available (until Premium launch)",
            available: "Launching Q3 2025"
        },
        {
            name: "Premium",
            price: "$15 / month",
            features: [
                "Everything in Starter Tier",
                "Limited AI exercises (Translation, Writing, Conversation, Pronunciation)",
            ],
            trial: "1 week free trial available (upon Premium launch)",
            available: "Launching 2026"
        },
        {
            name: "Unlimited",
            price: "$20 / month",
            features: [
                "Everything in Premium Tier",
                "Unlimited access to AI exercises"
            ],
            available: "Launching 2026"
        }
    ];

    return (
        <section id="pricing" className="p-8 rounded-2xl bg-blue-50 shadow-xl my-10 border border-blue-200">
            <h2 className="text-3xl font-extrabold text-gray-700 text-center mb-4">
                Tentative Launch Pricing
            </h2>
            <div className="max-w-3xl mx-auto mb-8 p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
                <strong>Note:</strong> During our beta period (until launch), all completed features are free and unlimited.
            </div>
            <div className="grid md:grid-cols-4 gap-4">
                {plans.map((plan, index) => (
                    <div 
                        key={index} 
                        className="p-6 bg-white rounded-xl shadow-lg border border-gray-300 flex flex-col justify-between"
                    >
                        <div>
                            <h3 className="text-2xl font-bold text-gray-800">{plan.name}</h3>
                            <p className="text-xl text-gray-600 font-semibold mt-2">{plan.price}</p>
                            <p className="text-sm text-gray-500 italic mb-3">{plan.available}</p>
                            <ul className="mt-4 space-y-2">
                                {plan.features.map((feature, idx) => (
                                    <li key={idx} className="text-gray-700">✓ {feature}</li>
                                ))}
                            </ul>
                        </div>
                        {plan.trial && (
                            <p className="mt-4 text-center font-bold text-gray-800">
                                {plan.trial}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Pricing;
